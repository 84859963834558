import React, { useContext } from "react"
import { Box, Checkbox, FormControlLabel } from "@mui/material"
import { StreetDataContext } from "../../../index"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: "white",
  },
  checkbox: {
    color: "white !important",
  },
})

const StreetImsgeControlLinkView = (props) => {
  const classes = useStyles()
  const { state, setIsMapLinked } = useContext(StreetDataContext)

  const changeHandler = (event, value) => {
    setIsMapLinked(value)
  }

  return (
    <Box className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={state.isMapLinked}
            onChange={changeHandler}
          />
        }
        label="画像と地図を連動"
      />
    </Box>
  )
}

export default StreetImsgeControlLinkView
