import React from "react"
import { Box, Typography, TypographyVariantsOptions } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { ResizableBox } from "react-resizable"

const useStyles = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    position: "relative",
  },
  base: {
    width: "100%",
    height: "100%",
  },
  resize: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "green",
  },
})

const StreetResizeTest = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.base}>
        <Typography>テスト</Typography>
      </Box>
      <ResizableBox
        style={{ position: "absolute" }}
        width={200}
        height={200}
        minConstraints={[100, 100]}
        maxConstraints={[300, 300]}
      >
        <Box className={classes.resize}>
          <Typography>ほげ</Typography>
        </Box>
      </ResizableBox>
    </Box>
  )
}

export default StreetResizeTest
