import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { AgGridReact } from "ag-grid-react"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material"
import { StreetDataContext } from "../../index"
import StreetManager from "../../../../manager/street"
import { Check } from "@mui/icons-material"
import SelectedRenderer from "./renderer/selected"
import LOCALE_JA from "../../../../resources/aggrid/locale.ja"

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  grid: {
    backgroundColor: "white",
    height: "calc(100% - 32px)",
    flex: 1,
  },
})

const StreetTableView = (props) => {
  const classes = useStyles()
  const { state, setEditedDatas, setSelectedDatas } =
    useContext(StreetDataContext)
  const [rowData, setRowData] = useState()
  const [isMapBounds, setIsMapBounds] = useState(true)
  const [deleteData, setDeleteData] = useState()
  const gridRef = useRef()

  const contextMenuItems = useCallback(
    (params) => {
      return [
        "copy",
        {
          name: "この樹木データを抹消",
          subMenu: [
            {
              name: "枯損として抹消",
              action: () => {
                setDeleteData({
                  ...params.node.data,
                  remove_status: 1,
                })
              },
            },
            {
              name: "移設として抹消",
              action: () => {
                setDeleteData({
                  ...params.node.data,
                  remove_status: 2,
                })
              },
            },
            {
              name: "不明・その他として抹消",
              action: (p) => {
                setDeleteData({
                  ...params.node.data,
                  remove_status: 9,
                })
              },
            },
          ],
        },
      ]
    },
    [state.selectedDatas]
  )

  const confirmDelete = (status) => {}

  const handleSubmitClose = useCallback(() => {
    let d = state.editedDatas?.filter((v) => v !== deleteData.tree_id) ?? []
    d.push(deleteData)
    setEditedDatas(d)
    setDeleteData(null)

    let sel = (state.selectedDatas ?? []).filter(
      (v) => v.tree_id !== deleteData.tree_id
    )
    setSelectedDatas(sel)
  }, [deleteData, state.selectedDatas])

  const columnDefs = useMemo(
    () => [
      {
        field: "selected",
        displayName: "選択",
        cellRenderer: "selectedRenderer",
        width: 70,
        editable: false,
      },
      {
        field: "edited",
        displayName: "編集",
        valueGetter: (params) => {
          return params.data.edited ? "★" : ""
        },
        width: 70,
        filter: false,
        editable: false,
      },
      { field: "tree_id", displayName: "樹木ID" },
      { field: "map_name", displayName: "地図ID" },
      { field: "citycode", displayName: "市区町村コード", width: 90 },
      { field: "n03_004", displayName: "市区町村名", width: 120 },
      { field: "ai_tree_uid", displayName: "AI判別ID", width: 120 },
      { field: "remarks", displayName: "調査ステータス", width: 90 },
      { field: "dist", displayName: "距離" },
      { field: "longitude", displayName: "経度" },
      { field: "latitude", displayName: "緯度" },
      { field: "user_created_at", displayName: "最終更新編日" },
      { field: "user_uid", displayName: "最終更新ユーザ" },
    ],
    []
  )

  useEffect(() => {
    let selectedIds = state.selectedDatas?.map((v) => v.tree_id)

    StreetManager.getDaichoBoundsListData(
      isMapBounds ? state.mapBounds : null,
      state.selectedData,
      state.editedDatas
    ).then((res) => {
      let d = res.map((v) => {
        return {
          ...v,
          selected: selectedIds?.includes(v.tree_id),
        }
      })
      setRowData(d)
    })
  }, [state.mapBounds, state.selectedDatas, state.editedDatas, isMapBounds])

  const handleCloseConfirmDelete = () => {
    setDeleteData(null)
  }

  return (
    <Box className={classes.root}>
      <AgGridReact
        ref={gridRef}
        className={classes.grid + " ag-theme-balham"}
        columnDefs={columnDefs}
        localeText={LOCALE_JA}
        defaultColDef={{
          editable: true,
          resizable: true,
        }}
        rowData={rowData}
        rowSelection="multiple"
        pagination={true}
        paginationAutoPageSize={true}
        getContextMenuItems={contextMenuItems}
        frameworkComponents={{
          selectedRenderer: SelectedRenderer,
        }}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          height: "32px",
          marginLeft: "8px",
        }}
      >
        <FormGroup>
          <FormControlLabel
            style={{ color: "white" }}
            control={
              <Checkbox
                size="small"
                style={{ color: "white" }}
                defaultChecked={isMapBounds}
                onClick={() => setIsMapBounds(!isMapBounds)}
              />
            }
            label={<Typography fontSize="small">地図の表示範囲のみ</Typography>}
          />
        </FormGroup>
      </Box>
      <Dialog open={!!deleteData} onClose={handleCloseConfirmDelete}>
        <DialogTitle>本当に削除しますか</DialogTitle>
        <DialogContent>
          右下の「変更の保存」を押すまでは削除は完了しません。未保存中に「変更の破棄」を押すと削除を復元することができます
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitClose}>削除する</Button>
          <Button onClick={handleCloseConfirmDelete}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

StreetTableView.propTypes = {
  className: PropTypes.string,
}

export default StreetTableView
