var _ = require('underscore');
var Backbone = require('backbone');

/**
 * Base layer object.
 *
 * This object should not be used directly! use {@link carto.layer.Layer} instead.
 *
 * @constructor
 * @abstract
 * @fires error
 * @memberof carto.layer
 * @api
 */
function Base (source, layer, options) {
  options = options || {};
  this._id = options.id || Base.$generateId();
}

_.extend(Base.prototype, Backbone.Events);

/**
 * Get the unique autogenerated id.
 *
 * @return {string} Unique autogenerated id
 *
 */
Base.prototype.getId = function () {
  return this._id;
};

/**
 * The instance id will be autogenerated by incrementing this variable.
 */
Base.$nextId = 0;

/**
 * Static funciton used internally to autogenerate source ids.
 */
Base.$generateId = function () {
  return 'L' + ++Base.$nextId;
};

/**
 * Return the real CARTO.js model used by the layer.
 */
Base.prototype.$getInternalModel = function () {
  return this._internalModel;
};

module.exports = Base;
