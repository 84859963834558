import PropTypes from "prop-types"
import React, { useState } from "react"
import { Box } from "@mui/material"
import FrameView from "./frame"
import ControlView from "./control"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    backgroundColor: "inherit",
  },
})

const StreetImageView = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.root + " " + props.className}>
      <FrameView />
      <ControlView routeData={props.routeData} />
    </Box>
  )
}

StreetImageView.propTypes = {
  className: PropTypes.string,
  routeData: PropTypes.any,
}

export default StreetImageView
