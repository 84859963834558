import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { useRef } from "react"
import { makeStyles } from "@mui/styles"
import * as L from "leaflet/dist/leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-easybutton/src/easy-button"
import "leaflet-easybutton/src/easy-button.css"
import "font-awesome/css/font-awesome.css"

import "leaflet-rotatedmarker/leaflet.rotatedMarker"
import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"

import RouteLogLayer from "./layer/routeLog"
import DaichoLayer from "./layer/daichoPoint"
import Editor2 from "./editor2"
import baseLayers, { defaultLayer } from "./baseLayers"
import { StreetDataContext } from "../../index"
import StreetManager from "../../../../manager/street"

const useStyles = makeStyles({
  root: {
    backgroundColor: "inherit",
    position: "relative",
  },
  map: {
    width: "100%",
    height: "100%",
  },
})

let mapInitialized = false
let mapInitializeTimer

const StreetMapView = (props) => {
  const { state, setMapBounds } = useContext(StreetDataContext)
  const classes = useStyles()
  const mapRef = useRef()
  const [map, setMap] = useState()
  const markerLayer = useMemo(
    () => L.layerGroup({ interactive: true, zIndex: 100 }),
    []
  )

  useEffect(() => {
    require("leaflet/dist/leaflet.css")
    require("leaflet-rotatedmarker/leaflet.rotatedMarker")
  }, [])

  useEffect(() => {
    if (!mapRef.current || mapInitialized) {
      return
    }
    mapInitialized = true

    clearTimeout(mapInitializeTimer)

    mapInitializeTimer = setTimeout(() => {
      let m = L.map(mapRef.current, {
        maxZoom: 21,
        ...props.mapOptions,
      })
      m.on("moveend", (e) => {
        setMapBounds(m.getBounds())
        //        console.log(e, m.getBounds())
      })
      m.setView([35.67267693428627, 139.5445125511879], 14)

      L.control.layers(baseLayers).addTo(m)
      setMap(m)
    }, 100)
  }, [mapRef.current])

  useEffect(() => {
    if (!map) {
      return
    }

    map.on("moveend", (e) => {
      console.log(e)
    })
    map.on("load resize", (e) => {
      console.log(e)
    })

    defaultLayer.addTo(map)
    markerLayer.addTo(map)
  }, [map])

  useEffect(() => {
    if (!map || !state.isMapLinked || !state.frame) {
      return
    }

    StreetManager.getPositionFromFrameWithOffset(
      state.frame,
      state.frameOffset
    ).then((res) => {
      map.setView(
        { lat: res.latitude, lng: res.longitude },
        map.getZoom() < 18 ? 18 : map.getZoom()
      )
      //      console.log(res, map.getZoom())
    })

    //    console.log(state.routeData)
  }, [map, state.frame, state.isMapLinked])

  return (
    <Box className={classes.root + " " + props.className}>
      <Box className={classes.map} ref={mapRef} />
      <RouteLogLayer map={map} />
      <DaichoLayer map={map} />
      <Editor2 map={map} />
    </Box>
  )
}

StreetMapView.propTypes = {
  className: PropTypes.string,
  mapOptions: PropTypes.object,
  onActive: PropTypes.func,
}

export default StreetMapView
