import PropTypes from "prop-types"
import CartoQueryLayer from "../../../../../manager/cartoQueryLayer"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { StreetDataContext } from "../../../index"
import * as L from "leaflet/dist/leaflet"
import StreetManager from "../../../../../manager/street"
import { LayerGroup } from "leaflet/dist/leaflet-src.esm"

const selectedMarkerIcon = L.icon({
  iconUrl: require("../../../../../resources/images/editing-marker.png"),
  iconRetinaUrl: require("../../../../../resources/images/editing-marker-x2.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [6, 0],
})

const editedNoRemarkedMarkerIcon = L.icon({
  iconUrl: require("../../../../../resources/images/daicho_edited_marker.png"),
  iconRetinaUrl: require("../../../../../resources/images/daicho_edited_marker_2x.png"),
  iconSize: [7, 7],
  iconAnchor: [4, 4],
})

const editedRemarkedMarkerIcon = L.icon({
  iconUrl: require("../../../../../resources/images/daicho_edited_marker_remerking.png"),
  iconRetinaUrl: require("../../../../../resources/images/daicho_edited_marker_remerking_2x.png"),
  iconSize: [7, 7],
  iconAnchor: [4, 4],
})

const StreetMapDaichoPointLayer = (props) => {
  const { state, setSelectedDatas, setEditedDatas, setIsEdited } =
    useContext(StreetDataContext)

  const [clickedFeature, setClickedFeature] = useState()
  const [selectedLayerGroup, setSelectedLayerGroup] = useState()
  const [editedLayerGroup, setEditedLayerGroup] = useState()

  const selectedDatas = useRef(state.selectedDatas)
  useEffect(() => {
    selectedDatas.current = state.selectedDatas
  }, [state.selectedDatas])

  const query = useMemo(() => {
    return StreetManager.getDaichoLayerQuery(
      state.selectedDatas,
      state.editedDatas
    )
  }, [state.selectedDatas, state.editedDatas])

  useEffect(() => {
    if (!props.map) {
      return
    }

    let sl = L.layerGroup().addTo(props.map)
    setSelectedLayerGroup(sl)
    let el = L.layerGroup().addTo(props.map)
    setEditedLayerGroup(el)

    return () => {
      if (selectedLayerGroup) {
        props.map.removeLayer(selectedLayerGroup)
        setSelectedLayerGroup(null)
        props.map.removeLayer(editedLayerGroup)
        setEditedLayerGroup(null)
      }
    }
  }, [props.map])

  useEffect(() => {
    if (!selectedLayerGroup) {
      return
    }

    selectedLayerGroup.eachLayer((l) => {
      selectedLayerGroup.removeLayer(l)
    })

    state.selectedDatas?.forEach((v) => {
      if (v.remove_status) {
        return
      }
      L.marker(
        { lat: v.latitude, lng: v.longitude },
        {
          icon: selectedMarkerIcon,
          zIndex: 10100,
          zIndexOffset: 10,
          draggable: true,
          data: v,
        }
      )
        .on("dragend", (e) => {
          let datas = Array.from(state.selectedDatas)
          let diffLat =
            e.target.options.data.latitude - e.target.getLatLng().lat
          let diffLng =
            e.target.options.data.longitude - e.target.getLatLng().lng
          let dataIds = []
          datas = datas.map((f) => {
            f.latitude = f.latitude - diffLat
            f.longitude = f.longitude - diffLng
            f.edited = true
            dataIds.push(f.tree_id)
            return f
          })
          setSelectedDatas(datas)

          let edited = state.editedDatas?.filter(
            (v) => !dataIds.includes(v.tree_id)
          )
          setEditedDatas(edited.concat(datas))
          !state.isEdited && setIsEdited(true)
        })
        .addTo(selectedLayerGroup)
    })
  }, [selectedLayerGroup, state.selectedDatas, state.editedDatas])

  useEffect(() => {
    if (!editedLayerGroup) {
      return
    }

    editedLayerGroup.eachLayer((l) => {
      editedLayerGroup.removeLayer(l)
    })

    let selectedTreeIds = state.selectedDatas?.map((v) => v.tree_id) ?? []

    state.editedDatas?.forEach((v) => {
      // 選択中の場合は無視
      if (selectedTreeIds.includes(v.tree_id)) {
        return
      }
      if (v.remove_status) {
        return
      }

      L.marker(
        { lat: v.latitude, lng: v.longitude },
        {
          icon:
            v.remark === "要測定"
              ? editedNoRemarkedMarkerIcon
              : editedRemarkedMarkerIcon,
          zIndex: 10010,
          zIndexOffset: 5,
          data: v,
        }
      )
        .addTo(editedLayerGroup)
        .on("click", (e) => {
          console.log(e)
          let d = e.target?.options?.data
          if (d) {
            let selVal = Array.from(state.selectedDatas ?? []).filter(
              (v) => v.tree_id !== d.tree_id
            )
            selVal.push(d)
            setSelectedDatas(selVal)
          }
        })
    })
  }, [editedLayerGroup, state.selectedDatas, state.editedDatas])

  const clickFeature = useCallback((e) => {
    if (!e.data) {
      return
    }

    let datas = Array.from(
      selectedDatas.current?.filter((v) => v.tree_id !== e.data.tree_id) ?? []
    )
    datas.push(e.data)
    setSelectedDatas(datas)
  }, [])

  return (
    <CartoQueryLayer
      style={{ color: "#32d358" }}
      map={props.map}
      cartoCss={`
          #layer {
              marker-width: 7;
              marker-fill: #4D5AC9;
              marker-fill-opacity: 0.9;
              marker-line-color: #FFFFFF;
              marker-line-width: 1;
              marker-line-opacity: 1;
              marker-placement: point;
              marker-type: ellipse;
              marker-allow-overlap: true;
           }
           #layer[remarks='要再計測'] {
              marker-fill: #8E353F;        
           }
           #layer[edited='true'] {
             marker-line-color: #c8d332;
             marker-line-width: 1.1;
           }
          `}
      query={query}
      zIndex={1030}
      featureClickColumns={[
        "tree_id",
        "latitude",
        "longitude",
        "fid",
        "id",
        "citycode",
        "temp_tree_",
        "map_name",
        "n03_004",
        "ai_tree_uid",
        "dist",
        "user_uid",
        "description",
        "remove_status",
        "user_created_at",
        "remarks",
      ]}
      onFeatureClick={clickFeature}
    />
  )
}

StreetMapDaichoPointLayer.propTypes = {
  map: PropTypes.any,
}

export default StreetMapDaichoPointLayer
