import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import CartoQueryLayer from "../../../../../manager/cartoQueryLayer"
import { StreetDataContext } from "../../../index"
import { Rows } from "../../../../../manager/carto"
import * as L from "leaflet/dist/leaflet"

const routeCurrentPositionIcon = L.icon({
  iconUrl: require("../../../../../resources/images/route-icon.png"),
  iconRetinaUrl: require("../../../../../resources/images/route-icon_x2.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
})

const routeFixedCurrentPositionIcon = L.icon({
  iconUrl: require("../../../../../resources/images/fixed-route-icon.png"),
  iconRetinaUrl: require("../../../../../resources/images/fixed-route-icon_x2.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
})

const StreetMapRouteLogLayer = (props) => {
  const { state, setActiveData, setFrame, setFrameOffset } =
    useContext(StreetDataContext)

  const routeMarker = useMemo(() => {
    if (!props.map) {
      return null
    }

    let m = L.marker([0, 0], {
      icon: routeCurrentPositionIcon,
      zIndexOffset: 1020,
    })

    m.addTo(props.map)
    return m
  }, [props.map])

  const fixedRouteMarker = useMemo(() => {
    if (!props.map) {
      return null
    }

    let m = L.marker([0, 0], {
      icon: routeFixedCurrentPositionIcon,
      zIndexOffset: 1030,
    })

    m.addTo(props.map)
    return m
  }, [props.map])

  const anglePolyline = useMemo(() => {
    if (!props.map) {
      return null
    }

    let l = L.polyline(
      [
        [0, 0],
        [0, 0],
      ],
      {
        strokeWidth: 1,
        color: "#1f1fec",
      }
    )
    l.addTo(props.map)
    return l
  }, [props.map])

  const fixedAnglePolyline = useMemo(() => {
    if (!props.map) {
      return null
    }

    let l = L.polyline(
      [
        [0, 0],
        [0, 0],
      ],
      {
        strokeWidth: 1,
        color: "#1de1c4",
      }
    )
    l.addTo(props.map)
    return l
  }, [props.map])

  useEffect(() => {
    if (!routeMarker || !anglePolyline || !state.frame || !state.frameData) {
      return
    }

    let d = state.frameData[state.frame]
    if (!d) {
      return
    }

    routeMarker.setLatLng([d.latitude, d.longitude])
    routeMarker.setRotationAngle(d.bearing)

    // 座標計算
    let dx = 100 * Math.cos(d.bearing * (Math.PI / 180.0))
    let dy = 100 * Math.sin(d.bearing * (Math.PI / 180.0))

    // webmercatorの取得
    const proj = L.CRS.EPSG3857

    let meter = proj.project(new L.LatLng(d.latitude, d.longitude))
    let ll1 = proj.unproject(new L.Point(meter.x + dx, meter.y - dy))
    let ll2 = proj.unproject(new L.Point(meter.x - dx, meter.y + dy))

    anglePolyline.setLatLngs([ll1, ll2])
  }, [routeMarker, anglePolyline, state.frame, state.frameData])

  useEffect(() => {
    if (
      !fixedRouteMarker ||
      !fixedAnglePolyline ||
      !state.frame ||
      !state.frameData
    ) {
      return
    }
    if (!state.frameOffset) {
      fixedRouteMarker.setLatLng([0, 0])
      fixedAnglePolyline.setLatLngs([
        [0, 0],
        [0, 0],
      ])
      return
    }

    let fNum = state.frame + (state.frameOffset ?? 0)
    if (fNum < 0) {
      fNum = 0
    }
    if (fNum > state.frameData.length - 1) {
      fNum = state.frameData.length - 1
    }
    let d = state.frameData[fNum]

    if (!d) {
      return
    }

    fixedRouteMarker.setLatLng([d.latitude, d.longitude])
    fixedRouteMarker.setRotationAngle(d.bearing)

    // 座標計算
    let dx = 100 * Math.cos(d.bearing * (Math.PI / 180.0))
    let dy = 100 * Math.sin(d.bearing * (Math.PI / 180.0))

    // webmercatorの取得
    const proj = L.CRS.EPSG3857

    let meter = proj.project(new L.LatLng(d.latitude, d.longitude))
    let ll1 = proj.unproject(new L.Point(meter.x + dx, meter.y - dy))
    let ll2 = proj.unproject(new L.Point(meter.x - dx, meter.y + dy))

    fixedAnglePolyline.setLatLngs([ll1, ll2])
  }, [
    fixedRouteMarker,
    fixedAnglePolyline,
    state.frame,
    state.frameOffset,
    state.frameData,
  ])

  return (
    <CartoQueryLayer
      map={props.map}
      query={`SELECT * FROM cartoapp.matsuda_20211106_101117_00_001_route_log`}
      cartoCss={`
      #layer {
        marker-width: 2;
        marker-fill: #e4e1e1;
        marker-fill-opacity: 0.9;
        marker-allow-overlap: true;
        marker-transform: rotate([bearing]);  
      }
      #layer[zoom>18] {
        marker-width: 4;      
      }
      #current[tree_snap_cartodb_id!=null] {
        marker-fill: #9e9fef;
        marker-width: 7;
      }
      `}
      zIndex={1010}
      featureClickColumns={["cartodb_id", "start_frame"]}
      onFeatureClick={(e) => {
        setActiveData(e.data)
        setFrame(e.data.start_frame)
        let f = state.frameData[e.data.start_frame]
        setFrameOffset(f?.frame_offset ?? 0)
      }}
    />
  )
}

StreetMapRouteLogLayer.propTypes = {
  map: PropTypes.any,
}

export default StreetMapRouteLogLayer
