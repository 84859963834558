import React from "react"
import PropTypes from "prop-types"
import MapView from "./map"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DataControlView from "./dataControl"

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  map: {
    flex: 1,
    overflow: "hidden",
  },
})

const StreetRightView = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.root + " " + props.className}>
      <MapView className={classes.map} />
      <DataControlView />
    </Box>
  )
}

StreetRightView.propTypes = {
  className: PropTypes.string,
}

export default StreetRightView
