import * as L from "leaflet/dist/leaflet"

const baseLayers = {
  ダークモード: L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
    {}
  ),
  衛星写真: L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
    { maxNativeZoom: 18, maxZoom: 22 }
  ),
  道路台帳: L.tileLayer(
    "https://tokyo-g-mgmt.s3.ap-northeast-1.amazonaws.com/tiles/dourodaicho/merged_01_ichi-ken/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 21, maxZoom: 22 }
  ),
  地理院地形図: L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  街区地図: L.tileLayer(
    "https://api.mapbox.com/styles/v1/pacificspatial/ckwv1ijut1z4i15ldi8xot35b/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicGFjaWZpY3NwYXRpYWwiLCJhIjoiY2p1amlzYTVkMWh6NTN5bnF3cXZwa3F5YyJ9.3_50pwnF9CBMFhddvpE9-g",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
}

export const defaultLayer = baseLayers["地理院地形図"]

export default baseLayers
