import { useReducer } from "react"
import reducer, { ActionType } from "./reducer"
import initialState from "./state"
import { v4 as uuidv4 } from "uuid"
import StreetManager from "../../../manager/street"

const UseStreetViewData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const setMapCenter = (value) => {
    dispatch({ type: ActionType.SetMapCenter, value: value })
  }

  const setMapBounds = (value) => {
    dispatch({ type: ActionType.SetMapBounds, value: value })
  }

  const setPlayTime = (value) => {
    dispatch({ type: ActionType.SetPlayTime, value: value })
  }

  const setActiveData = (value) => {
    dispatch({ type: ActionType.SetActiveData, value: value })
  }

  const refreshRouteData = () => {
    StreetManager.getRouteData().then((rows) => {
      dispatch({ type: ActionType.SetRouteData, value: rows })
      let frameData = Object.fromEntries(
        rows.map((row) => [parseInt(row["frame"]), row])
      )
      dispatch({ type: ActionType.SetFrameData, value: frameData })
    })
  }

  const setFrame = (value) => {
    dispatch({ type: ActionType.SetFrame, value: value })
  }

  const setFrameOffset = (value) => {
    dispatch({ type: ActionType.SetFrameOffset, value: value })
  }

  const setEditMode = (value) => {
    dispatch({ type: ActionType.SetEditMode, value: value })
    dispatch({ type: ActionType.SetIsEdited, value: false })
    dispatch({ type: ActionType.SetDeleteMode, value: false })
  }

  const setDeleteMode = (value) => {
    dispatch({ type: ActionType.SetDeleteMode, value: value })
  }

  const setIsEdited = (value) => {
    if (value) {
      dispatch({ type: ActionType.SetSession, value: uuidv4() })
    } else {
      dispatch({ type: ActionType.SetSelectedDatas, value: null })
    }
    dispatch({ type: ActionType.SetIsEdited, value: value })
  }

  const setSelectedDatas = (value) => {
    dispatch({ type: ActionType.SetSelectedDatas, value: value })
  }

  const addSelectedDatas = (values) => {
    let selected = Array.from(state.selectedDatas ?? []).concat(values)
    dispatch({ type: ActionType.SetSelectedDatas, value: selected })
  }

  const refreshSession = () => {
    dispatch({ type: ActionType.SetSession, value: uuidv4() })
  }

  const setEditedDatas = (value) => {
    dispatch({ type: ActionType.SetEditedDatas, value: value })
    if (value) {
      localStorage.setItem("street_edited_data", JSON.stringify(value))
    } else {
      localStorage.removeItem("street_edited_data")
    }
  }

  const setIsMapLinked = (value) => {
    dispatch({ type: ActionType.SetIsMapLinked, value: value })
  }

  return {
    state,
    setMapBounds,
    setMapCenter,
    setPlayTime,
    setActiveData,
    refreshRouteData,
    setFrame,
    setFrameOffset,
    setEditMode,
    setDeleteMode,
    setIsEdited,
    setEditedDatas,
    setSelectedDatas,
    addSelectedDatas,
    refreshSession,
    setIsMapLinked,
  }
}

export default UseStreetViewData
