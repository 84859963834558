import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Button, IconButton, TextField, Typography } from "@mui/material"
import { AddCircle, RemoveCircle } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { StreetDataContext } from "../../../index"
import StreetManager from "../../../../../manager/street"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  box: {
    width: "100px",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    backgroundColor: "inherit",
  },
  text: {
    textAlign: "center",
    fontSize: "12px !important",
    color: "white",
  },
  icon: {
    color: "white",
  },
})

let updateTimer = null

const StreetImageControlOffsetView = (props) => {
  const { state, setFrameOffset, refreshRouteData } =
    useContext(StreetDataContext)
  const classes = useStyles()
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (isNaN(state.frameOffset)) {
      return
    }

    if (value === state.frameOffset) {
      return
    }
    setValue(state.frameOffset)
  }, [state.frameOffset])

  useEffect(() => {
    if (!value || isNaN(value) || value === state.frameOffset) {
      return
    }

    try {
      updateTimer?.clearTimeout()
    } catch (e) {
      console.log(e)
    }

    updateTimer = null
    updateTimer = setTimeout(() => {
      updateTimer = null
      setFrameOffset(parseInt(value))
    }, 100)
  }, [value])

  return (
    <Box className={classes.root}>
      <Box className={classes.button}>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        <Typography className={classes.text}>　</Typography>
        <IconButton onClick={() => setValue(value - 1)}>
          <RemoveCircle className={classes.icon} fontSize="large" />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>マップ位置調整</Typography>
        <TextField
          size="small"
          style={{ backgroundColor: "white", margin: "auto" }}
          value={value ?? "0"}
          onChange={(e) => setValue(e.target.value)}
        />
      </Box>
      <Box className={classes.button}>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        <Typography className={classes.text}>　</Typography>
        <IconButton onClick={() => setValue(value + 1)}>
          <AddCircle className={classes.icon} fontSize="large" />
        </IconButton>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{ marginLeft: "16px", marginTop: "15px" }}
          onClick={() =>
            StreetManager.setFrameOffset(state.frame, state.frameOffset).then(
              () => {
                refreshRouteData()
              }
            )
          }
        >
          <Typography fontSize="small">この調整を保存する</Typography>
        </Button>
      </Box>
    </Box>
  )
}

StreetImageControlOffsetView.propTypes = {
  className: PropTypes.string,
}

export default StreetImageControlOffsetView
