import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  stepIconClasses,
  TextField,
  Typography,
} from "@mui/material"
import {
  SkipPrevious as SkipPreviousIcon,
  FastRewind as FastRewindIcon,
  FastForward as FastForwardIcon,
  PlayArrow as PlayArrowIcon,
  SkipNext as SkipNextIcon,
  Replay30 as Replay30Icon,
  Replay5 as Replay5Icon,
  Forward30 as Forward30Icon,
  Forward5 as Forward5Icon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { StreetDataContext } from "../../../index"
import { One } from "../../../../../manager/carto"
import FrameView from "./frame"
import OffsetView from "./offset"
import LinkView from "./link"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    gap: "56px",
  },
})

const StreetImageControlView = (props) => {
  const classes = useStyles()
  const { state } = useContext(StreetDataContext)

  const onChangeMapConnect = (value) => {
    console.log(value)
  }

  return (
    <Box className={classes.root}>
      <FrameView />
      <OffsetView />
      <LinkView />
    </Box>
  )
}

StreetImageControlView.propTypes = {
  className: PropTypes.string,
  routeData: PropTypes.array,
}

export default StreetImageControlView
