import { v4 as uuidv4 } from "uuid"

const StreetViewDataState = {
  mapBounds: null,
  playTime: 1,
  mapCenter: null,
  activeData: null,
  routeData: null,
  frameData: null,
  frame: 1,
  frameOffset: null,
  session: uuidv4(),
  editMode: false,
  deleteMode: false,
  isEdited: false,
  selectedDatas: null,
  editedDatas: localStorage.getItem("street_edited_data")
    ? JSON.parse(localStorage.getItem("street_edited_data"))
    : null,
  isMapLinked: false,
}

export default StreetViewDataState
