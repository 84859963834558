import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import * as L from "leaflet/dist/leaflet"
import { useCallback, useContext, useEffect, useState } from "react"
import { StreetDataContext } from "../../index"
import StreetManager from "../../../../manager/street"

const selectedMarkerIcon = L.icon({
  iconUrl: require("../../../../resources/images/editing-marker.png"),
  iconRetinaUrl: require("../../../../resources/images/editing-marker-x2.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [6, 0],
})

const newPointMarkerIcon = L.icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconSize: [15, 25],
  iconAnchor: [8, 25],
})

const StreetMapEditor2 = (props) => {
  const { state, setSelectedDatas, setEditedDatas } =
    useContext(StreetDataContext)
  const [drawControl, setDrawControl] = useState()
  const [dataControl, setDataControl] = useState()

  let isUnselectClickEvent = false

  const addSelection = (bounds, selectedDatas, editedDatas) => {
    StreetManager.getDiachoBoundsSelection(
      bounds,
      selectedDatas,
      editedDatas
    ).then((val) => {
      if (val.length > 0) {
        setSelectedDatas(val)
      } else {
        setSelectedDatas(null)
      }
    })
  }

  const addPoint = (latLng, editedDatas) => {
    // getNewDaichoData
    StreetManager.getNewDaichoData(latLng.lat, latLng.lng).then((val) => {
      let datas = Array.from(editedDatas ?? [])
      datas.push({
        edited: true,
        latitude: latLng.lat,
        longitude: latLng.lng,
        remove_status: 0,
        remarks: "新規",
        ...val,
      })
      setEditedDatas(datas)
    })
  }

  const updateEditFromSelection = useCallback(
    (selected, edited) => {
      let e = edited
        ? Object.fromEntries(edited.map((v) => [v.tree_id, v]))
        : {}

      selected?.forEach((v) => {
        if (v.edited) {
          e[v.tree_id] = v
        }
      })
      setEditedDatas(Object.values(e))
      setSelectedDatas(null)
    },
    [props.map, state.selectedDatas, state.editedDatas]
  )

  const handleDrawCreated = useCallback(
    (e) => {
      isUnselectClickEvent = false

      switch (e.layerType) {
        case "marker":
          addPoint(e.layer.getLatLng(), state.editedDatas)
          break
        case "rectangle":
          addSelection(
            e.layer.getBounds(),
            state.selectedDatas,
            state.editedDatas
          )
          break
        default:
          break
      }
    },
    [props.map, state.selectedDatas, state.editedDatas]
  )

  useEffect(() => {
    if (!props.map) {
      return
    }

    props.map.on("draw:created", handleDrawCreated)
    props.map.on("click", (e) => {
      isUnselectClickEvent = true
      setTimeout(() => {
        isUnselectClickEvent = false
      }, 1000)
    })
    return () => {
      props.map.off("draw:created")
      props.map.off("click")
    }
  }, [props.map, state.selectedDtas, state.editedDatas, drawControl])

  useEffect(() => {
    if (!props.map) {
      return
    }

    let _drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        circlemarker: false,
        marker: {
          icon: newPointMarkerIcon,
        },
      },
      edit: {
        featureGroup: L.featureGroup().addTo(props.map),
        edit: false,
        remove: false,
      },
      position: "topleft",
    }).addTo(props.map)

    setDrawControl(_drawControl)

    let _dataControl = L.easyBar(
      [
        L.easyButton({
          states: [
            {
              stateName: "ban",
              icon: "fa-ban",
              title: "選択を解除",
              onClick: (btn, map) => {
                updateEditFromSelection(state.selectedDatas, state.editedDatas)
              },
            },
          ],
        }),
      ],
      {
        position: "topleft",
      }
    ).addTo(props.map)

    setDataControl(_dataControl)

    return () => {
      _drawControl.remove(props.map)
      setDrawControl(null)
      _dataControl.remove(props.map)
      setDataControl(null)
    }
  }, [props.map, state.selectedDatas, state.editedDatas])

  useEffect(() => {
    if (!drawControl) {
      return
    }

    //    return
    L.drawLocal.draw.toolbar.actions.text = "中止"
    L.drawLocal.draw.toolbar.actions.title = "中止する"
    L.drawLocal.draw.toolbar.buttons.rectangle = "矩形選択"
    L.drawLocal.draw.toolbar.buttons.marker = "樹木を追加"

    L.drawLocal.draw.handlers.simpleshape.tooltip.end =
      "マウスを離して選択範囲を決定"
    L.drawLocal.draw.handlers.marker.tooltip.start =
      "樹木を追加する場所でクリック"
    L.drawLocal.draw.handlers.rectangle.tooltip.start =
      "開始位置からドラッグして範囲で選択"
  }, [drawControl])

  return null
}

StreetMapEditor2.propTypes = {
  map: PropTypes.any,
}

export default StreetMapEditor2
