import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ImageView from "./image"
import TableView from "./table"
import { Box, Button, ButtonGroup } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
})

const ViewType = {
  image: "image",
  list: "list",
}

const StreetLeftView = (props) => {
  const classes = useStyles()
  const [viewType, setViewType] = useState(ViewType.image)

  return (
    <Box className={classes.root}>
      <Box
        style={{
          margin: "8px 0",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <ButtonGroup>
          <Button
            variant={viewType === ViewType.image ? "contained" : "outlined"}
            onClick={() => setViewType(ViewType.image)}
          >
            画像
          </Button>
          <Button
            variant={viewType === ViewType.list ? "contained" : "outlined"}
            onClick={() => setViewType(ViewType.list)}
          >
            リスト
          </Button>
        </ButtonGroup>
      </Box>
      {viewType === ViewType.image && <ImageView />}
      {viewType === ViewType.list && <TableView />}
    </Box>
  )
}

StreetLeftView.propTypes = {
  className: PropTypes.string,
}

export default StreetLeftView
