export const ActionType = {
  SetMapCenter: "SET_MAP_CENTER",
  SetMapBounds: "SET_MAP_BOUNDS",
  SetPlayTime: "SET_PLAY_TIME",
  SetActiveData: "SET_ACTIVE_DATA",
  SetRouteData: "SET_ROUTE_DATA",
  SetFrameData: "SET_FRAME_DATA",
  SetFrame: "SET_FRAME",
  SetFrameOffset: "SET_FRAME_OFFSET",
  SetEditMode: "SET_EDIT_MODE",
  SetDeleteMode: "SET_DELETE_MODE",
  SetIsEdited: "SET_IS_EDITED",
  SetSelectedDatas: "SET_SELECTED_DATAS",
  SetEditedDatas: "SET_EDITED_DATAS",
  SetSession: "SET_SESSION",
  SetIsMapLinked: "Set_IS_MAP_LINKED",
}

const StreetViewDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetMapCenter:
      return { ...state, mapCenter: action.value }
    case ActionType.SetMapBounds:
      return { ...state, mapBounds: action.value }
    case ActionType.SetPlayTime:
      return { ...state, playTime: action.value }
    case ActionType.SetActiveData:
      return { ...state, activeData: action.value }
    case ActionType.SetRouteData:
      return { ...state, routeData: action.value }
    case ActionType.SetFrameData:
      return { ...state, frameData: action.value }
    case ActionType.SetFrame:
      return { ...state, frame: action.value }
    case ActionType.SetFrameOffset:
      return { ...state, frameOffset: action.value }
    case ActionType.SetEditMode:
      return { ...state, editMode: action.value }
    case ActionType.SetDeleteMode:
      return { ...state, deleteMode: action.value }
    case ActionType.SetIsEdited:
      return { ...state, isEdited: action.value }
    case ActionType.SetSelectedDatas:
      return { ...state, selectedDatas: action.value }
    case ActionType.SetEditedDatas:
      return { ...state, editedDatas: action.value }
    case ActionType.SetSession:
      return { ...state, session: action.value }
    case ActionType.SetIsMapLinked:
      return { ...state, isMapLinked: action.value }
    default:
      break
  }

  return state
}

export default StreetViewDataReducer
