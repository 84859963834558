import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"
import UseStreetViewData from "./data"
import LeftView from "./left"
import RightView from "./right"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#282828",
    width: "calc(100% - 16px)",
    height: "calc(100% - 16px)",
    display: "flex",
    padding: "8px 8px 8px 8px",
    position: "relative",
    flexDirection: "row",
    gap: "16px",
  },
  right: {
    flex: 1,
    overflow: "hidden",
  },
  slider: {
    backgroundColor: "white",
  },
})

export const StreetDataContext = React.createContext()

const StreetView = (props) => {
  const useStreetData = UseStreetViewData()
  const classes = useStyles()
  const { refreshRouteData } = useStreetData

  useEffect(() => {
    refreshRouteData()
  }, [])

  return (
    <StreetDataContext.Provider value={useStreetData}>
      <Box className={classes.root}>
        <LeftView />
        <RightView className={classes.right} />
      </Box>
    </StreetDataContext.Provider>
  )
}

StreetView.propTypes = {
  className: PropTypes.string,
}

export default StreetView
