import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { ArrowRight as ArrowRightIcon } from "@mui/icons-material"
import Base from "./base"

const DialogDetailContentEditSelectEditor = (props) => {
  return (
    <Base {...props}>
      <FormControl size="small" sx={{ width: "100%" }}>
        <InputLabel id={`${props.id}-select-label`}>{props.label}</InputLabel>
        <Select
          sx={{ width: "100%" }}
          labelId={`${props.id}-select-label`}
          id={props.id}
          value={props.value}
          onChange={(e) => {
            let value = e.target.value
            if (value === "　") {
              value = null
            }
            props.onChange && props.onChange(value)
          }}
          label={props.label}
          defaultValue={props.current}
        >
          <MenuItem value="">{"　"}</MenuItem>
          {Object.keys(props.options).map((k) => {
            return (
              <MenuItem
                key={`${props.id}-${k}-item`}
                value={props.options[k].value}
              >
                {props.options[k].name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Base>
  )
}

DialogDetailContentEditSelectEditor.propTypes = {
  ...Base.props,
  value: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

export default DialogDetailContentEditSelectEditor
