import PropTypes from "prop-types"
import React, { useContext, useMemo, useState } from "react"
import { Box, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { sprintf } from "sprintf-js"
import { Fullscreen as FullscreenIcon } from "@mui/icons-material"
import { StreetDataContext } from "../../index"

const useStyles = makeStyles({
  root: {
    width: "990px",
    height: "540px",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  fullscreen_button: {
    position: "absolute !important",
    bottom: "0",
    right: "0",
  },
})

const StreetImageFrameView = (props) => {
  const classes = useStyles()
  const { state } = useContext(StreetDataContext)

  const url = useMemo(() => {
    if (!state.frame) {
      return null
    }

    return sprintf(process.env.REACT_APP_FRAME_IMAGE_URL, state.frame)
  }, [state.frame])

  return (
    <Box className={classes.root + " " + props.className}>
      <img className={classes.image} src={url} />
      {url && (
        <IconButton className={classes.fullscreen_button}>
          <FullscreenIcon style={{ color: "white" }} fontSize="large" />
        </IconButton>
      )}
    </Box>
  )
}

StreetImageFrameView.propTypes = {
  className: PropTypes.string,
  frame: PropTypes.number,
}

export default StreetImageFrameView
