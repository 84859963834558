import { First, One, Query, Rows } from "./carto"

export default new (class {
  getStandardQuery = () => {
    return `
    WITH cte AS (
  SELECT
    the_geom,
    the_geom_webmercator,
    fid,
    id,
    tree_id,
    citycode,
    temp_tree_,
    map_name,
    n03_004,
    ai_tree_uid,
    remarks,
    dist,
    user_uid,
    user_created_at,
    description,
    remove_status
  FROM daicho_hondo_20220511_edited
  UNION ALL
  SELECT
    the_geom,
    the_geom_webmercator,
    fid,
    id,
    tree_id,
    citycode,
    temp_tree_,
    map_name,
    n03_004,
    ai_tree_uid,
    remarks,
    dist,
    NULL AS user_uid,
    NULL AS user_created_at,
    NULL AS description,
    NULL AS remove_status
  FROM daicho_hondo_20220511
  WHERE NOT EXISTS (SELECT cartodb_id FROM daicho_hondo_20220511_edited WHERE daicho_hondo_20220511.tree_id = daicho_hondo_20220511_edited.tree_id)
)
SELECT ROW_NUMBER() OVER() AS cartodb_id, * FROM cte
    `
  }

  _getEnvelopeFromBounds = (bounds) => {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    return `ST_MakeEnvelope(${ne.lng}, ${ne.lat}, ${sw.lng}, ${sw.lat}, 4326)`
  }

  _getSnapQueryWithoutEditTarget = (
    bounds,
    selectedDatas,
    editedDatas,
    deleteFlags,
    wheres = []
  ) => {
    wheres.push(`remove_status IN (${deleteFlags.join(",")})`)

    if (bounds) {
      wheres.push(`
      ST_Contains(${this._getEnvelopeFromBounds(bounds)}, the_geom)
      `)
    }

    if (selectedDatas && selectedDatas.length > 0) {
      wheres.push(`
      tree_id NOT IN ('${selectedDatas.map((v) => v.tree_id).join("','")}')
      `)
    }
    if (editedDatas && editedDatas.length > 0) {
      wheres.push(`
      tree_id NOT IN ('${editedDatas.map((v) => v.tree_id).join("','")}')
      `)
    }

    return `
    SELECT * FROM daicho_hondo_20220511_snap_view
    WHERE
        ${wheres.join(" AND ")}
    `
  }

  _getBaseDatas = (
    bounds,
    selectedDatas,
    editedDatas,
    removeStatuses,
    wheres
  ) => {
    return new Promise((resolve, reject) => {
      wheres.push(`remove_status IN (${removeStatuses.join(",")})`)

      if (bounds) {
        wheres.push(`
        ST_Contains(${this._getEnvelopeFromBounds(bounds)}, the_geom)
        `)
      }

      if ((selectedDatas?.length ?? 0) > 0) {
        wheres.push(`
        tree_id NOT IN ('${selectedDatas.map((v) => v.tree_id).join("','")}')
        `)
      }
      if ((editedDatas?.length ?? 0) > 0) {
        wheres.push(`
        tree_id NOT IN ('${editedDatas.map((v) => v.tree_id).join("','")}')
        `)
      }

      Rows(`
      SELECT * FROM daicho_hondo_20220511_snap_view
      WHERE ${wheres.join(" AND ")}
      `)
        .then((rows) => {
          resolve(
            rows.concat(
              selectedDatas?.filter((v) =>
                removeStatuses.includes(v.remove_status)
              ) ?? [],
              editedDatas?.filter((v) =>
                removeStatuses.includes(v.remove_status)
              ) ?? []
            )
          )
        })
        .catch((e) => {
          console.log(e)
          reject(e)
        })
    })
  }

  getDaichoListData = (
    bounds,
    selectedDatas,
    editedDatas,
    deleteFlgs = [0]
  ) => {
    return new Promise((resolve, reject) => {
      Rows(
        this._getSnapQueryWithoutEditTarget(
          bounds,
          selectedDatas,
          editedDatas,
          deleteFlgs
        )
      )
        .then((rows) => {
          rows = rows.concat(selectedDatas).filter((v) => v.remove_status === 0)
          rows = rows.concat(editedDatas).filter((v) => v.remove_status === 0)

          resolve(
            rows.concat(
              selectedDatas?.filter((v) => v.remove_status === 0) ?? [],
              editedDatas?.filter((v) => v.remove_status === 0) ?? []
            )
          )
        })
        .catch((e) => {
          console.log(e)
          reject(e)
        })
    })
  }

  getDaichoData = (bounds, selectedDatas, editedDatas, session) => {
    return new Promise((resolve, reject) => {
      let wheres = ["delete_flg = false"]

      if (bounds) {
        wheres.push(
          `ST_Contains(${this.getEnvelopeFromBounds(bounds)}, the_geom)`
        )
      }
      if (selectedDatas) {
        if (selectedDatas.length === 0) {
          return resolve({ rows: [] })
        }

        wheres.push(`cartodb_id IN (${selectedDatas.map((v) => v.cartodb_id)})`)
      }

      Rows(`
      ${this.getBaseQuery(session)}
      SELECT * FROM base
      WHERE ${wheres.join(" AND ")}
      `)
        .then((res) => {
          //          console.log(res)
          resolve({ rows: res })
        })
        .catch((e) => {
          console.log(e)
          reject(e)
        })
    })
  }

  getDaichoLayerQuery = (selectedDatas, editedDatas, removeStatuses = [0]) => {
    let wheres = [`remove_status = 0`]

    if ((selectedDatas?.length ?? 0) > 0) {
      wheres.push(`
      tree_id NOT IN ('${selectedDatas.map((v) => v.tree_id).join("','")}')
      `)
    }
    if ((editedDatas?.length ?? 0) > 0) {
      wheres.push(`
      tree_id NOT IN ('${editedDatas.map((v) => v.tree_id).join("','")}')
      `)
    }

    return `
    SELECT * FROM daicho_hondo_20220511_snap_view
    WHERE ${wheres.join(" AND ")}
    `
  }

  getDaichoBoundsListData = (
    bounds,
    selectedDatas,
    editedDatas,
    removeStatuses = [0]
  ) => {
    return new Promise((resolve, reject) => {
      let query =
        this.getDaichoLayerQuery(selectedDatas, editedDatas, removeStatuses) +
        ` AND ST_Contains(${this._getEnvelopeFromBounds(bounds)}, the_geom)`

      let editedTreeIds = (editedDatas ?? []).map((v) => v.tree_id)

      let mixed = Array.from(editedDatas ?? [])
        .concat(
          selectedDatas?.map((v) =>
            editedTreeIds.include(v.tree_id) ? null : v
          ) ?? []
        )
        .filter((v) => !!v)

      Rows(query).then((rows) => {
        console.log(mixed, rows)
        resolve((rows ?? []).concat(mixed))
      })
    })
  }

  getDiachoBoundsSelection = (
    bounds,
    selectedDatas,
    editedDatas,
    removeStatuses = [0]
  ) => {
    return new Promise((resolve, reject) => {
      let query =
        this.getDaichoLayerQuery(selectedDatas, editedDatas, removeStatuses) +
        ` AND ST_Contains(${this._getEnvelopeFromBounds(bounds)}, the_geom)`

      let selectedTreeIds = selectedDatas?.map((v) => v.tree_id) ?? []
      let edited = editedDatas
        ? Object.fromEntries(editedDatas.map((v) => [v.tree_id, v]))
        : {}

      Rows(query).then((rows) => {
        let s = selectedDatas ? Array.from(selectedDatas) : []
        rows.forEach((r) => {
          if (!selectedTreeIds.includes(r.tree_id)) {
            if (edited[r.tree_id]) {
              s.push(edited[r.tree_id])
            } else {
              s.push(r)
            }
          }
        })
        resolve(s)
      })
    })
  }

  getNewIds = () => {
    return new Promise((resolve, reject) => {
      First(`
        WITH cte1 AS (
          SELECT MAX(fid) AS fid
          FROM cartoapp.daicho_hondo_20220511_snap_view
        ),
        cte2 AS (
          SELECT MAX(id) AS id
          FROM cartoapp.daicho_hondo_20220511_snap_view
        )
        SELECT 
          COALESCE(fid, 0) + 1 AS fid,
          COALESCE(id, 0) + 1  AS id
        FROM cte1
        LEFT JOIN cte2 ON TRUE
      `)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getNewDaichoData = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
      First(`
      WITH cte1 AS (
        SELECT level500 AS map_name
        FROM nationalbasemap_tokyo
        WHERE
          ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(139.67737253576485,35.6750030577128),4326))
      ),
      cte2 AS (
        SELECT
          CAST(RIGHT(tree_id, 5) AS integer) + 1 as seq
        FROM daicho_hondo_20220511_edited AS foo
        INNER JOIN cte1 ON foo.map_name = cte1.map_name
        ORDER BY tree_id DESC
        LIMIT 1
      ),
      cte3 AS (
        SELECT
          N03_007 AS citycode,
          N03_004 AS cityname
        FROM tokyo_gyoseiku
        WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(139.67737253576485,35.6750030577128),4326)) AND N03_007 IS NOT NULL
        LIMIT 1
      )
      SELECT
        cte1.map_name,
        cte1.map_name || LPAD(CAST(COALESCE(cte2.seq,0) AS varchar), 5, '0') AS tree_id,
        citycode,
        cityname AS n03_004
      FROM cte1
      LEFT JOIN cte2 ON TRUE
      LEFT JOIN cte3 ON TRUE
      `)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  commitEditDatas = (editedDatas, user) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let queries = []

      let maxIds = await this.getNewIds()
      let fid = maxIds.fid
      let id = maxIds.id

      editedDatas.forEach((d) => {
        if (d.remove_status !== 0) {
          queries.push(`
            INSERT INTO daicho_hondo_20220511_edited (tree_id, user_uid, user_created_at, remove_status)
            VALUES ('${d.tree_id}', '${user.uid}', NOW(), ${d.remove_status})
          `)
        } else {
          queries.push(`
          INSERT INTO daicho_hondo_20220511_edited
          (fid, id, tree_id, citycode, map_name, n03_004, user_uid, user_created_at, remarks, the_geom, remove_status)
          VALUES
          (${d.fid ?? fid++},${d.id ?? id++}, '${d.tree_id}', '${
            d.citycode
          }', '${d.map_name}', '${d.n03_004}', '${user.uid}', NOW(), '${
            d.remarks
          }',
           ST_SetSRID(ST_MakePoint(${d.longitude},${d.latitude}),4326), 0
          )
          `)
        }
      })
      console.log("[Update queries]", queries)
      Query(queries.join(";"))
        .then(() => {
          resolve()
        })
        .catch((e) => {
          console.log(e)
          reject(e)
        })
    })
  }

  setFrameOffset = (frame, offset) => {
    return new Promise((resolve, reject) => {
      let sql = `
    INSERT INTO matsuda_20211106_101117_00_001_route_log_frame_offset (frame, frame_offset) 
    VALUES (${frame}, ${offset})
    `
      Query(sql)
        .then(() => resolve())
        .catch((e) => reject(e))
    })
  }

  getPositionFromFrameWithOffset = (frame, offset) => {
    return new Promise((resolve, reject) => {
      let sql = `
        WITH cte AS (
          SELECT
            foo.frame as s_frame,
            COALESCE(bar.frame, 2147483640) AS e_frame,
            foo.frame_offset
          FROM (SELECT ROW_NUMBER() OVER(order by frame) AS seq, * FROM matsuda_20211106_101117_00_001_route_log_frame_offset) AS foo
          LEFT JOIN (select row_number() over(order by frame) - 1 AS seq, frame FROM matsuda_20211106_101117_00_001_route_log_frame_offset) AS bar
          ON foo.seq = bar.seq
        ),
        cte2 AS (
          SELECT foo.time, foo.cartodb_id,
            generate_series((foo.start_frame + coalesce(cte.frame_offset, 0))::int, (foo.end_frame + coalesce(cte.frame_offset, 0))::int) AS frame,
            foo.latitude,
            foo.longitude,
            foo.bearing,
            coalesce(cte.frame_offset, 0)                   AS frame_offset
          FROM matsuda_20211106_101117_00_001_route_log AS foo
          LEFT JOIN cte
          ON foo.start_frame + coalesce(cte.frame_offset, 0) BETWEEN cte.s_frame + coalesce(cte.frame_offset, 0) AND cte.e_frame + coalesce(cte.frame_offset, 0)
        )
        SELECT * FROM cte2 WHERE frame = ${frame + offset}
        ORDER BY time DESC LIMIT 1
      `

      First(sql)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getRouteData = () => {
    return Rows(`
    WITH cte AS (
      SELECT
        foo.frame as s_frame,
        COALESCE(bar.frame, 2147483640) AS e_frame,
        foo.frame_offset
      FROM (SELECT ROW_NUMBER() OVER(order by frame) AS seq, * FROM matsuda_20211106_101117_00_001_route_log_frame_offset) AS foo
      LEFT JOIN (select row_number() over(order by frame) - 1 AS seq, frame FROM matsuda_20211106_101117_00_001_route_log_frame_offset) AS bar
      ON foo.seq = bar.seq
    )
    SELECT 
           foo.cartodb_id,
           generate_series(foo.start_frame, foo.end_frame) AS frame,
           foo.latitude,
           foo.longitude,
           foo.bearing,
           coalesce(cte.frame_offset,0) AS frame_offset
    FROM matsuda_20211106_101117_00_001_route_log AS foo
    LEFT JOIN cte
    ON foo.start_frame BETWEEN cte.s_frame AND cte.e_frame
    `)
  }
})()
