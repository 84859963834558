import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { StreetDataContext } from "../../../index"

const StreetTableSelectedRenderer = (props) => {
  const { state, setSelectedDatas } = useContext(StreetDataContext)

  const clickHandler = (e) => {
    props.node.setDataValue(props.column.colId, e.target.checked)
    let datas = state.selectedDatas ? Array.from(state.selectedDatas) : []
    if (e.target.checked) {
      if (
        state.selectedDatas?.find(
          (v) => v.cartodb_id == -props.data.cartodb_id
        ) ??
        false
      ) {
        return
      }
      datas.push({ ...props.data })
      setSelectedDatas(datas)
    } else {
      datas = datas.filter((v) => v.cartodb_id !== props.data.cartodb_id)
      setSelectedDatas(datas)
    }
  }

  return <input type="checkbox" checked={props.value} onChange={clickHandler} />
}

StreetTableSelectedRenderer.propTypes = {
  data: PropTypes.any,
  node: PropTypes.any,
  column: PropTypes.any,
  value: PropTypes.any,
  onClick: PropTypes.func,
}

export default StreetTableSelectedRenderer
