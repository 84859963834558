import PropTypes from "prop-types"
import { Box, IconButton, TextField, Typography } from "@mui/material"
import {
  Forward30 as Forward30Icon,
  Forward5 as Forward5Icon,
  PlayArrow as PlayArrowIcon,
  Replay30 as Replay30Icon,
  Replay5 as Replay5Icon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from "@mui/icons-material"
import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { StreetDataContext } from "../../../index"
import { One } from "../../../../../manager/carto"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    marginTop: "8px",
    justifyContent: "center",
  },
  reverse: {
    transform: "rotate(180deg)",
  },
  box: {
    width: "80px",
    display: "flex",
    flexDirection: "column",
  },
  text: {
    textAlign: "center",
    fontSize: "12px !important",
    color: "white",
  },
  icon: {
    //    textAlign: "center",
    //    alignItems: "center",
    //    justifyContent: "center",
    color: "white",
  },
})

let updateTimer = null

const StreetImageControlFrameView = (props) => {
  const { state, setFrame, setFrameOffset } = useContext(StreetDataContext)
  const classes = useStyles()

  const [value, setValue] = useState()
  const [endFrame, setEndFrame] = useState()

  useEffect(() => {
    One(`
    SELECT MAX(end_frame) FROM cartoapp.matsuda_20211106_101117_00_001_route_log
    `)
      .then((res) => {
        setEndFrame(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    setValue(state.frame)
  }, [state.frame])

  useEffect(() => {
    if (!value || isNaN(value) || !state.routeData || !state.frameData) {
      return
    }

    try {
      updateTimer?.clearTimeout()
    } catch (e) {
      console.log(e)
    }

    updateTimer = null
    updateTimer = setTimeout(() => {
      updateTimer = null
      setFrame(parseInt(value))
      let f = state.frameData[parseInt(value)]
      setFrameOffset(f?.frame_offset ?? 0)
    }, 100)
  }, [value, state.routeData, state.frameData])

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Typography className={classes.text}>前の樹木</Typography>
        <IconButton>
          <SkipPreviousIcon
            className={classes.icon}
            style={{ color: "gray" }}
          />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>30コマ戻す</Typography>
        <IconButton
          onClick={() => setValue(state.frame - 30)}
          disabled={state.frame <= 30}
        >
          <Replay30Icon
            className={classes.icon}
            style={{ color: state.frame <= 30 ? "gray" : "white" }}
          />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>5コマ戻す</Typography>
        <IconButton
          onClick={() => setValue(state.frame - 5)}
          disabled={state.frame <= 5}
        >
          <Replay5Icon
            className={classes.icon}
            style={{ color: state.frame <= 5 ? "gray" : "white" }}
          />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>1コマ戻す</Typography>
        <IconButton
          onClick={() => setValue(state.frame - 1)}
          disabled={state.frame <= 1}
        >
          <PlayArrowIcon
            className={classes.icon + " " + classes.reverse}
            style={{ color: state.frame <= 1 ? "gray" : "white" }}
          />
        </IconButton>
      </Box>
      <Box>
        <Typography className={classes.text}>フレーム番号</Typography>
        <TextField
          size={"small"}
          style={{
            backgroundColor: isNaN(value) ? "#f19c9c" : "white",
            color: "black",
          }}
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
        />
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>1コマ進む</Typography>
        <IconButton
          onClick={() => setValue(state.frame + 1)}
          disabled={!endFrame || endFrame - 1 < state.frame}
        >
          <PlayArrowIcon
            className={classes.icon}
            style={{
              color: !endFrame || endFrame - 1 < state.frame ? "gray" : "white",
            }}
          />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>5コマ進む</Typography>
        <IconButton
          onClick={() => setValue(state.frame + 5)}
          disabled={!endFrame || endFrame - 5 < state.frame}
        >
          <Forward5Icon
            className={classes.icon}
            style={{
              color: !endFrame || endFrame - 5 < state.frame ? "gray" : "white",
            }}
          />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>30コマ進む</Typography>
        <IconButton
          onClick={() => setValue(state.frame + 30)}
          disabled={!endFrame || endFrame - 30 < state.frame}
        >
          <Forward30Icon
            className={classes.icon}
            style={{
              color:
                !endFrame || endFrame - 30 < state.frame ? "gray" : "white",
            }}
          />
        </IconButton>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.text}>次の樹木</Typography>
        <IconButton>
          <SkipNextIcon className={classes.icon} style={{ color: "gray" }} />
        </IconButton>
      </Box>
    </Box>
  )
}

StreetImageControlFrameView.propTypes = {
  className: PropTypes.string,
}

export default StreetImageControlFrameView
