import PropTypes from "prop-types"
import carto from "@carto/carto.js"
import { useEffect, useMemo, useState } from "react"

const CartoQueryLayer = (props) => {
  const cartoClient = useMemo(() => {
    let c = new carto.Client({
      apiKey: process.env.REACT_APP_CARTO_API_KEY,
      username: process.env.REACT_APP_CARTO_USERNAME,
      serverUrl: process.env.REACT_APP_CARTO_SERVER_URL,
    })
    return c
  }, [])

  const [style, setStyle] = useState()
  const [source, setSource] = useState()
  const [cartoLayer, setCartoLayer] = useState()
  const [leafletLayer, setLeafletLayer] = useState()

  useEffect(() => {
    return () => {
      if (leafletLayer) {
        props.map?.removeLayer(leafletLayer)
      }
      if (cartoLayer) {
        cartoClient.removeLayer(cartoLayer)
      }
    }
  }, [])

  useEffect(() => {
    if (!props.cartoCss) {
      return
    }
    if (style) {
      style.setContent(props.cartoCss)
    } else {
      setStyle(new carto.style.CartoCSS(props.cartoCss))
    }
  }, [props.cartoCss])

  useEffect(() => {
    if (!props.query) {
      return
    }

    if (source) {
      if (props?.debug) {
        console.log("[CartoQueryLayer]", "set query", props.query)
      }
      source.setQuery(props.query)
    } else {
      if (props?.debug) {
        console.log("[CartoQueryLayer]", "update query", props.query)
      }
      setSource(new carto.source.SQL(props.query))
    }
  }, [props.query])

  useEffect(() => {
    if (!style || !source || cartoLayer) {
      return
    }

    let l = new carto.layer.Layer(source, style)
    l.on("featureClicked", props.onFeatureClick)
    cartoClient.addLayer(l)
    setCartoLayer(l)
  }, [style, source])

  useEffect(() => {
    if (!cartoLayer || props.featureClickColumns === undefined) {
      return
    }
    cartoLayer.setFeatureClickColumns(props.featureClickColumns)
  }, [cartoLayer, props.featureClickColumns, props.onFeatureClick])

  useEffect(() => {
    if (!cartoLayer || props.hidden === undefined) {
      return
    }

    if (props.hidden) {
      cartoLayer.hide()
    } else {
      cartoLayer.show()
    }
  }, [cartoLayer, props.hidden])

  useEffect(() => {
    if (!props.map) {
      return
    }

    let l
    if (!leafletLayer) {
      l = cartoClient.getLeafletLayer(props.leafletLayerOptions)
    } else {
      props.map.removeLayer(leafletLayer)
      l = cartoClient.getLeafletLayer(props.leafletLayerOptions)
    }
    l.addTo(props.map)
    l.on("loading", (e) => {
      props.onLoading && props.onLoading()
    })
    l.on("load", (e) => {
      props.onLoadFinished && props.onLoadFinished()
    })
    setLeafletLayer(l)
  }, [props.map, props.leafletLayerOptions])

  useEffect(() => {
    if (!leafletLayer || props.zIndex === undefined) {
      return
    }

    leafletLayer.setZIndex(props.zIndex)
  }, [leafletLayer, props.zIndex])

  useEffect(() => {
    if (!leafletLayer || props.opacity === undefined) {
      return
    }

    leafletLayer.setOpacity(props.opacity)
  }, [leafletLayer, props.opacity])

  return null
}

CartoQueryLayer.propTypes = {
  key: PropTypes.string,
  map: PropTypes.any,
  cartoCss: PropTypes.string,
  query: PropTypes.string,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  hidden: PropTypes.bool,
  featureClickColumns: PropTypes.array,
  onFeatureClick: PropTypes.func,
  onLoading: PropTypes.func,
  onLoadFinished: PropTypes.func,
  leafletLayerOptions: PropTypes.any,
  debug: PropTypes.bool,
}

export default CartoQueryLayer
