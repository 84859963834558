import React, { useContext } from "react"
import { Box, ButtonGroup, IconButton, Typography } from "@mui/material"
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material"
import { RootDataContext } from "../../index"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    width: "36px",
  },
})

const MapControlZoom = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)

  const onZoomIn = () => {
    if (!props.map) {
      return
    }
    if (props.map.getMaxZoom() <= state.mapZoom) {
      return
    }
    props.map.setZoom(state.mapZoom + 1)
  }

  const onZoomOut = () => {
    if (!props.map) {
      return
    }
    if (props.map.getMinZoom() >= state.mapZoom) {
      return
    }
    props.map?.setZoom(state.mapZoom - 1)
  }

  return (
    <ButtonGroup
      orientation="vertical"
      variant="contained"
      aria-label="contained button group"
      className={classes.root + " " + props.className}
    >
      <IconButton size="small" onClick={onZoomIn}>
        <AddIcon fontSize="small" />
      </IconButton>
      <Box textAlign="center" alignItems="center" justifyContent="center">
        <Typography style={{ color: "#999" }} fontSize="small">
          {state.mapZoom}
        </Typography>
      </Box>
      <IconButton size="small" onClick={onZoomOut}>
        <RemoveIcon fontSize="small" />
      </IconButton>
    </ButtonGroup>
  )
}

MapControlZoom.propTypes = {
  map: PropTypes.any,
  className: PropTypes.string,
}

export default MapControlZoom
