import React, { useCallback, useContext, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { StreetDataContext } from "../../index"
import StreetManager from "../../../../manager/street"

const useStyles = makeStyles({
  root: {
    height: "24px",
    position: "relative",
    margin: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
})

const StreetDataControlView = (props) => {
  const { state, setEditedDatas, setSelectedDatas } =
    useContext(StreetDataContext)
  const classes = useStyles()
  const [statusText, setStatusText] = useState()
  const [openConfirmReset, setOpenConfirmReset] = useState(false)
  const [openConfirmSave, setOpenConfirmSave] = useState(false)

  const handleCloseConfirmReset = () => {
    setOpenConfirmReset(false)
  }

  const handleCloseConfirmSave = () => {
    setOpenConfirmSave(false)
  }

  const handleSubmitReset = () => {
    setEditedDatas(null)
    setSelectedDatas(null)
    setOpenConfirmReset(false)
  }

  const handleSubmitSave = useCallback(() => {
    StreetManager.commitEditDatas(state.editedDatas, { uid: "Guest" }).then(
      () => {
        setEditedDatas(null)
        setSelectedDatas(null)
      }
    )
    setOpenConfirmSave(false)
  }, [state.editedDatas])

  useEffect(() => {
    if (!state.editedDatas) {
      return setStatusText("変更なし")
    }
    let added = 0
    let edited = 0
    let deleted = 0

    state.editedDatas?.forEach((v) => {
      if (!v.cartodb_id && v.remove_status === 0) {
        added += 1
      } else if (v.remove_status) {
        deleted += 1
      } else {
        edited += 1
      }
    })

    let labels = []
    if (added) {
      labels.push(`追加 ${added}件`)
    }
    if (edited) {
      labels.push(`編集 ${edited}件`)
    }
    if (deleted) {
      labels.push(`削除 ${deleted}件`)
    }

    if (labels.length === 0) {
      setStatusText("変更なし")
    } else {
      setStatusText("未保存 " + labels.join(" "))
    }
  }, [state.editedDatas])

  return (
    <Box className={classes.root}>
      <Typography fontSize="small" style={{ color: "white" }}>
        {statusText}
      </Typography>
      <Box style={{ flex: 1 }} />
      <Button
        variant="contained"
        color="error"
        disabled={!state.editedDatas}
        onClick={() => setOpenConfirmReset(true)}
      >
        変更の破棄
      </Button>
      <Button
        variant="contained"
        disabled={!state.editedDatas}
        onClick={() => setOpenConfirmSave(true)}
      >
        変更の保存
      </Button>
      <Dialog open={openConfirmReset} onClose={handleCloseConfirmReset}>
        <DialogTitle>本当に変更を破棄しますか</DialogTitle>
        <DialogContent>
          編集を破棄すると変更したデータが失われます。本当に破棄しますか
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitReset}>破棄する</Button>
          <Button onClick={handleCloseConfirmReset}>キャンセル</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmSave} onClose={handleCloseConfirmSave}>
        <DialogTitle>本当に変更を保存しますか</DialogTitle>
        <DialogContent>
          ツール上では変更を元に戻すことはできません（戻したい場合は管理者に連絡してください)
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitSave}>変更を保存する</Button>
          <Button onClick={handleCloseConfirmSave}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default StreetDataControlView
